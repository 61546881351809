// This file was automatically generated from manager.manage.venueservers.view.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Manage == 'undefined') { Nightloop.Templates.Manager.Manage = {}; }


Nightloop.Templates.Manager.Manage.VenueServersView = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Manage.VenueServersViewBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.VenueServersViewBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-venueservers"><div><div class="content-header float-left"><span class="header-text">Server Names</span>  <a id="contextual-link-servedby" class="contextual-link" href="javascript:void(0);">(?)</a><div id="contextual-content-servedby" class="contextual-content">These names appear in the \'Served by\' drop-down both on the iPad and when editing Actuals.</div></div><div class="float-end"></div></div><div id="main-area" class="float-left w50">', (opt_data.content.message) ? '<div class="white-box gold-message">' + soy.$$escapeHtml(opt_data.content.message) + '</div>' : '', '<div class="body-block white-box block-shadow"><div class="white-box-block"><div class="block-header"><div class="header-text float-left">Names</div><div id="edit-button" class="float-right"><a id="edit-servedby-link" class="ajaxify" href="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/manage/servernames/edit">');
  Nightloop.Templates.Widget.GoldButton({text: 'EDIT', size: 'medium'}, output);
  output.append('</a></div><div class="float-end"></div></div></div><div class="white-box-block">');
  var venue_serverList1047 = opt_data.content.venue_servers;
  var venue_serverListLen1047 = venue_serverList1047.length;
  for (var venue_serverIndex1047 = 0; venue_serverIndex1047 < venue_serverListLen1047; venue_serverIndex1047++) {
    var venue_serverData1047 = venue_serverList1047[venue_serverIndex1047];
    output.append('<div class="venue-server-name">', soy.$$escapeHtml(venue_serverData1047.name), '</div>');
  }
  output.append('</div></div></div><div class="float-end"></div></div><script type="text/javascript">$(document).ready( function() {Pmp.Manager.Manage.VenueServersView.initialize();})<\/script>');
  return opt_sb ? '' : output.toString();
};
