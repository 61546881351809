// This file was automatically generated from manager.actual.partialedit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Actual == 'undefined') { Nightloop.Templates.Manager.Actual = {}; }


Nightloop.Templates.Manager.Actual.PartialEditPopup = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Main.Popup.PopupLayout(soy.$$augmentData(opt_data, {div_id: 'manager-edit-partial-res-popup', header_html: 'Edit Reservation', show_close_link: 'false', DynamicTemplate: 'Nightloop.Templates.Manager.Actual.PartialEditPopupBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Actual.PartialEditPopupBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<form id="edit-partial-res-form" method="POST" action="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/actual/', soy.$$escapeHtml(opt_data.content.actual.id), '/partial-edit"><div id="actual-details-display" class="body-block"><div class="block-title">', soy.$$escapeHtml(opt_data.content.actual.client_display_name), '</div><div class="editable-area"><div><div class="float-left w35 right indent-right">Reservation No.</div><div class="float-left w60 number">', soy.$$escapeHtml(opt_data.content.actual.reference_code), '</div><div class="float-end"></div></div><div><div class="float-left w35 right indent-right">Date:</div><div class="float-left w60">', soy.$$escapeHtml(opt_data.content.actual.date_formatted_long_display), '</div><div class="float-end"></div></div>');
  if (opt_data.content.can_pick_seating) {
    if (opt_data.content.actual.system_class == 'BAR') {
      output.append('<div><div class="float-left w35 right indent-right">Table No.:</div><div class="float-left w60">Bar</div><div class="float-end"></div></div>');
    } else {
      output.append('<div><div class="float-left w35 right indent-right">Seating Area:</div><div class="float-left w60"><select id="select-seating-area" name="venue_seating_area_id">');
      if (opt_data.content.actual.is_reservation) {
        var seating_areaList13743 = opt_data.content.venue_seating_areas;
        var seating_areaListLen13743 = seating_areaList13743.length;
        for (var seating_areaIndex13743 = 0; seating_areaIndex13743 < seating_areaListLen13743; seating_areaIndex13743++) {
          var seating_areaData13743 = seating_areaList13743[seating_areaIndex13743];
          output.append((opt_data.content.actual.venue_seating_area_id == seating_areaData13743.id) ? '<option value="' + soy.$$escapeHtml(seating_areaData13743.id) + '" selected="selected" >' + soy.$$escapeHtml(seating_areaData13743.code) + '</option>' : '');
        }
      } else {
        output.append('<option value=""></option>');
        var seating_areaList13754 = opt_data.content.venue_seating_areas;
        var seating_areaListLen13754 = seating_areaList13754.length;
        for (var seating_areaIndex13754 = 0; seating_areaIndex13754 < seating_areaListLen13754; seating_areaIndex13754++) {
          var seating_areaData13754 = seating_areaList13754[seating_areaIndex13754];
          output.append('<option value="', soy.$$escapeHtml(seating_areaData13754.id), '"', (opt_data.content.actual.venue_seating_area_id == seating_areaData13754.id) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(seating_areaData13754.code), '</option>');
        }
      }
      output.append('</select></div><div class="float-end"></div></div><div><div class="float-left w35 right indent-right">Table No.:</div><div class="float-left w60"><select id="select-seating-table" name="table_ids"><option value=""></option>');
      var tableList13766 = opt_data.content.all_table_inventory_items;
      var tableListLen13766 = tableList13766.length;
      for (var tableIndex13766 = 0; tableIndex13766 < tableListLen13766; tableIndex13766++) {
        var tableData13766 = tableList13766[tableIndex13766];
        output.append('<option value="', soy.$$escapeHtml(tableData13766.id), '"', (opt_data.content.actual.table_id == tableData13766.id) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(tableData13766.item_code), ' (', soy.$$escapeHtml(tableData13766.party_size_range_display), ')</option>');
      }
      output.append('</select><select id="sac_to_tables_all" class="no-display"><option value=""></option>');
      var tableList13780 = opt_data.content.all_table_inventory_items;
      var tableListLen13780 = tableList13780.length;
      for (var tableIndex13780 = 0; tableIndex13780 < tableListLen13780; tableIndex13780++) {
        var tableData13780 = tableList13780[tableIndex13780];
        output.append('<option value="', soy.$$escapeHtml(tableData13780.id), '">', soy.$$escapeHtml(tableData13780.item_code), ' (', soy.$$escapeHtml(tableData13780.party_size_range_display), ')</option>');
      }
      output.append('</select>');
      var sac_tList13790 = opt_data.content.seatingareacodes_to_tables;
      var sac_tListLen13790 = sac_tList13790.length;
      for (var sac_tIndex13790 = 0; sac_tIndex13790 < sac_tListLen13790; sac_tIndex13790++) {
        var sac_tData13790 = sac_tList13790[sac_tIndex13790];
        if (sac_tData13790.tables.length > 0) {
          output.append('<select id="sac_to_tables_', soy.$$escapeHtml(sac_tData13790.id), '" class="no-display"><option value=""></option>');
          var tableList13796 = sac_tData13790.tables;
          var tableListLen13796 = tableList13796.length;
          for (var tableIndex13796 = 0; tableIndex13796 < tableListLen13796; tableIndex13796++) {
            var tableData13796 = tableList13796[tableIndex13796];
            output.append('<option value="', soy.$$escapeHtml(tableData13796.id), '">', soy.$$escapeHtml(tableData13796.item_code), ' (', soy.$$escapeHtml(tableData13796.party_size_range_display), ')</option>');
          }
          output.append('</select>');
        }
      }
      output.append('</div><div class="float-end"></div></div>');
    }
  }
  output.append('<div><div class="float-right supersubtext"> characters remaining: <span id=\'venue-notes-remaining\' class=\'number\' ></span></div><div class="float-end"></div></div><div><div class="float-left w35 right indent-right">Notes:</div><div class="float-left w60"><textarea id="venue-notes-textarea" name="venue_notes" maxlength="150">', (opt_data.content.actual.notes) ? soy.$$escapeHtml(opt_data.content.actual.notes) : '', '</textarea></div><div class="float-end"></div></div></div></div><div class="save-links"><div class="float-right">');
  Nightloop.Templates.Widget.GoldButton({text: 'SAVE', size: 'medium', id: 'edit-partial-res-submit-btn'}, output);
  output.append('</div><div class="float-right"><a href="javascript:void(0);" id="edit-partial-res-cancel-btn">cancel</a></div></div></form><script type="text/javascript">$(document).ready( function() {var tableids_to_seatingareaids = {};');
  if (opt_data.content.actual.system_class == 'TABLE') {
    var sac_to_tList13820 = opt_data.content.seatingareacodes_to_tables;
    var sac_to_tListLen13820 = sac_to_tList13820.length;
    for (var sac_to_tIndex13820 = 0; sac_to_tIndex13820 < sac_to_tListLen13820; sac_to_tIndex13820++) {
      var sac_to_tData13820 = sac_to_tList13820[sac_to_tIndex13820];
      var tableList13821 = sac_to_tData13820.tables;
      var tableListLen13821 = tableList13821.length;
      for (var tableIndex13821 = 0; tableIndex13821 < tableListLen13821; tableIndex13821++) {
        var tableData13821 = tableList13821[tableIndex13821];
        output.append('tableids_to_seatingareaids[\'', soy.$$escapeHtml(tableData13821.id), '\'] = \'', soy.$$escapeHtml(sac_to_tData13820.id), '\';');
      }
    }
  }
  output.append('Pmp.Manager.Actual.PartialEdit.initialize( "', soy.$$escapeHtml(opt_data.venue.manager_base_url), '", "', soy.$$escapeHtml(opt_data.content.actual.id), '", tableids_to_seatingareaids);});<\/script>');
  return opt_sb ? '' : output.toString();
};
